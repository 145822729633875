import { ref, watch } from '@vue/composition-api';
import store from '@/store';

import toast from '@/utils/toast';

export default function useShortCut() {
  // Use toast
  const toastification = toast();
  const apartment = ref(null);
  const layouts = ref([]);
  const isLoading = ref(false);
  const room = ref(null);
  const bed = ref(null);

  //   API Call

  const getApartmentLayout = () => {
    if (apartment) {
      isLoading.value = true;
      store
        .dispatch('apartment/getApartmentLayout', { apartmentId: apartment.value ? apartment.value.id : 0 })
        .then(response => {
          layouts.value = response.data;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = false;
        });
    } else {
      layouts.value = [];
    }
  };

  const viewRoomDetail = val => {
    room.value = val;
  };

  const viewBedDetail = val => {
    bed.value = val;
  };

  //   Watch
  watch(apartment, () => {
    getApartmentLayout();
  });

  return {
    apartment,
    layouts,
    isLoading,
    room,
    bed,
    getApartmentLayout,
    viewRoomDetail,
    viewBedDetail,
  };
}
