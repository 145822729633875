<template>

  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ t('Sơ đồ tòa nhà') }}</b-card-title>

      </b-card-header>
      <b-card-body>
        <select-apartment
          v-model="apartment"
          :enable-default-select="true"
        />
        <b-overlay
          id="overlay-background"
          :show="isLoading"
          variant="light"
          opacity="0.75"
          rounded="sm"
        >
          <b-list-group
            v-if="layouts && layouts.length > 0"
            class="list-group-shortcut"
          >
            <b-list-group-item
              v-for="(apartment,index) in layouts"
              :key="index"
            >
              <apartment-item
                :apartment="apartment"
                @view-room-detail="viewRoomDetail"
                @view-bed-detail="viewBedDetail"
              />
            </b-list-group-item>
          </b-list-group>

          <div
            v-if="layouts.length === 0 && !isLoading"
            class="misc-inner p-2 p-sm-3"
          >
            <div class="w-100 text-center">
              <h2 class="mb-1">
                {{ t('Không tìm thấy thông tin cho tòa nhà này') }} 🕵🏻‍♀️
              </h2>
              <p class="mb-2">
                {{ t('Có vẻ như bạn chưa tạo phòng/giường nào cho tòa nhà này. Vui lòng tạo dữ liệu để theo dõi.') }}
              </p>

            <!-- image -->

            </div>
          </div>
        </b-overlay>

      </b-card-body>
    </b-card>

    <room-detail-modal :room-id="room ? room.id : 0" />
    <bed-detail-modal :bed-id="bed ? bed.id : 0" />

  </div>

</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BListGroup, BListGroupItem, BCardBody, BOverlay, VBModal,
} from 'bootstrap-vue';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import SelectApartment from '../components/SelectApartment.vue';
import useShortCut from './useShortCut';
import RoomDetailModal from '../property/room/detail/RoomDetailModal.vue';
import BedDetailModal from '../property/bed/detail/BedDetailModal.vue';
import ApartmentItem from '../sale/ApartmentItem.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BCardBody,
    BOverlay,

    SelectApartment,
    ApartmentItem,
    RoomDetailModal,
    BedDetailModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  setup() {
    const { t } = useI18nUtils();
    const {
      apartment, layouts, isLoading, room, bed, getApartmentLayout, viewRoomDetail, viewBedDetail,
    } = useShortCut();

    return {
      apartment, layouts, isLoading, room, bed, getApartmentLayout, viewRoomDetail, viewBedDetail, t,
    };
  },
};
</script>

<style lang="scss" scoped>
.list-group-shortcut {
  .list-group-item {
    line-height: 1.5;

    &:hover {
      background-color: transparent;
    }

  }

}
</style>
